
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    useComponentDidMount,
    useTitle
  } from "../scripts/Utils.js";
  import Bottom_page_images from './Bottom_page_images';
import {BiCheckCircle} from "react-icons/bi";
import Img_lazy_load from './Img_lazy_load.js';
import MiniClinic_baner from './MiniClinic_baner.js';
//const Img_lazy_load = React.lazy(() => import('./Img_lazy_load'));
export default function SpecialSnoring(){

    //useTitle("Pozbądź się problemu z chrapaniem!");

    useComponentDidMount(() => {
        window.scrollTo(0, 0);
      });

 

    return(
        <>
            <MiniClinic_baner />
      
        <div className='home-page-info'>
                    
            <div className='home-page-title'>
                <h1>Chrapanie i Bezdech Senny - Nasze Metody Leczenia</h1>
                <hr className='separator-line' />
            </div>
            
            <div className='consultationBody'>
            <div className='consultationBody-title'>
            </div>
            <div className='consultationBody-content'>
                <div className='consultationBody-content-img_text'>
                    <div className='consultationBody-content-text'>
                        <h2>Zespół Obturacyjnego Bezdechu Sennego - Częsty Powód Chrapania</h2>
                        <span className='content-text'>
                            
                        <span className='bookmark-text'>Zespół obturacyjnego bezdechu sennego (ZOBS)</span> to poważne, potencjalnie zagrażające życiu zaburzenie oddychania
                         podczas snu, które dotyka obecnie około 2,5 miliona Polaków. Badania wykazały, że ZOBS występuje u około 34% mężczyzn i 17% kobiet w grupie wiekowej
                          od 30 do 70 lat, a <span className='bookmark-text'>w grupie wiekowej od 50 do 70 lat wskaźnik ten wzrasta do 43% u mężczyzn i 28% u kobiet</span>.
                          Niestety, większość przypadków tego zaburzenia pozostaje niezdiagnozowana, a jedynie 10% pacjentów z objawami zwraca się do specjalisty. 
                        Wykazano, że częstość incydentów sercowo-naczyniowych zakończonych zgonem oraz niezakończonych zgonem w grupie 
                         Pacjentów z ciężkim zespołem obturacyjnego bezdechu sennego była znacząco wyższa niż w grupie kontrolnej zdrowych osób populacji ogólnej.
                            <br /> <br />
                        <span className='bookmark-text'>Chrapanie jest nierzadkim objawem ZOBS</span>, który często skłania pacjentów do skonsultowania się z lekarzem.
                         Jednak należy pamiętać, że chrapanie to tylko jeden z wielu objawów ZOBS, które pogarszają jakość życia zarówno w nocy, jak i w ciągu dnia.
                         </span>

                     
                    </div>
                   
                    <div className='consultationBody-content-img-container'>
                                    <Img_lazy_load
                                        isSrcSet={false}
                                        srcString={'/images/specjalnosci/problem-z-chrapaniem.webp'}
                                        imgWidth={'773'}
                                        imgHeight={'515'}
                                        altString={'Najlepsze leczenie chrapania i bezdechu sennego w Rzeszowie'}
                                        classString={'consultationBody-content-img'} />
                    </div>
                </div>

               
                <span className='content-text'>
                Bezdech senny występuje w postaci krótkich przerw w oddychaniu podczas snu. Podczas tych epizodów, próby wdychania powietrza powodują zapadanie się tchawicy,
                            co prowadzi do blokady przepływu powietrza przez okres od 10 sekund do nawet jednej minuty. W odpowiedzi na spadek poziomu tlenu we krwi, <span className='bookmark-text'>mózg wybudza osobę,
                            aby napięła mięśnie górnych dróg oddechowych i otworzyła tchawicę. Osoba bierze wdech, a następnie ponownie zasypia. Ten cykl może powtarzać się setki razy w ciągu jednej nocy. </span>
                            Częste wybudzenia powodują stałe zmęczenie, a także mogą prowadzić do zmian osobowości, takich jak drażliwość czy depresja.
                       
                </span>


                <div className='consultationBody-content-only_text'>
                            <h2>Powikłania Nieleczonego Bezdechu Sennego:</h2>

                            <span className='consultationBody-content-check-list-parent content-text'>
                            <span className='consultationBody-content-check-list-child'>1) nadciśnienie tętnicze,</span>
                            <span className='consultationBody-content-check-list-child'>2) zaburzenia rytmu serca,</span>
                            <span className='consultationBody-content-check-list-child'>3) cukrzyca,</span>
                            <span className='consultationBody-content-check-list-child'>4) choroba niedokrwienna serca,</span>
                            <span className='consultationBody-content-check-list-child'>5) niewydolność serca,</span>
                            <span className='consultationBody-content-check-list-child'>6) udar mózgu,</span>
                            <span className='consultationBody-content-check-list-child'>7) wypadki komunikacyjne.</span>
                            </span>
                </div>

                <h2>Jak Rozpoznać Bezdech Senny?</h2>
                <span>
                    Bezdechy powodują niedotlenienie organizmu, co jest przyczyną objawów nocnych i dziennych.
                </span>
                        <div className='consultationBody-content-only_text-doubled-parent'>
                            <div className='consultationBody-content-only_text first'>
                                <span className='bookmark-text'>Nocne Objawy Bezdechu Sennego :</span>
                                <span className='consultationBody-content-check-list-parent'>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> chrapanie,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> bezdechy (obserwowane przez osoby z otoczenia chorego),</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> nykturia, czyli konieczność wyjścia do toalety,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> zwiększona potliwość,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> przebudzenia z uczuciem dławienia,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> duszność,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> suchość w jamie ustnej,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> kołatania serca,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> objawy refluksu żołądkowo-przełykowego,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> trudności z zaśnięciem, bezsenność.</span>
                                </span>
                            </div>

                            <div className='consultationBody-content-only_text'>
                                <span className='bookmark-text'>Dzienne Objawy Bezdechu Sennego :</span>
                                <span className='consultationBody-content-check-list-parent'>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> senność dzienna,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> poranne zmęczenie,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> poranne bóle głowy,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> zaburzenia pamięci i koncentracji,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> upośledzenie libido i impotencja,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> zaburzenia psycho-emocjonalne, np. depresja.</span>
                                </span>
                            </div>
                        </div>

                <hr className='separator-line-simple'></hr>

                <h2 className='h2-margin-top'>Zdrowy Sen To Klucz Do Zdrowego Życia</h2>

                <span>Codzienne chrapanie i bezdech senny mogą być cichymi zabójcami nocy, pozbawiającymi Cię nie tylko spokojnego snu, ale także zdrowia i energii.
                 Konsekwencje braku dobrego snu sięgają daleko, wpływając negatywnie na Twoje zdrowie fizyczne i psychiczne.
                  Nie martw się jednak – istnieją skuteczne metody leczenia chrapania i bezdechu sennego, które mogą przywrócić Ci spokojny sen i poprawić jakość życia.
                </span>
                <br/><br/>

               {/* <span>Dlaczego to ważne?</span>
                    <span className='consultationBody-content-check-list-parent content-text'>
                    <span className='consultationBody-content-check-list-child'><span className='bookmark-text'>1) Zdrowie Serca i Naczyń Krwionośnych: </span>
                    Bezdech senny zwiększa ryzyko chorób serca, udaru mózgu i nadciśnienia tętniczego. Leczenie tego zaburzenia może wspomóc utrzymanie zdrowego układu sercowo-naczyniowego.</span>
                    <span className='consultationBody-content-check-list-child'><span className='bookmark-text'>2) Poprawa Koncentracji i Uczucia Witalności: </span> 
                    Brak właściwego snu z powodu chrapania prowadzi do chronicznego zmęczenia i spadku koncentracji w ciągu dnia. Skuteczne leczenie pomaga przywrócić energię i zwiększyć efektywność codziennych działań.</span>
                    <span className='consultationBody-content-check-list-child'><span className='bookmark-text'>3) Zmniejszenie Ryzyka Chorób Neurologicznych: </span>
                    Badania wskazują, że bezdech senny może zwiększać ryzyko chorób neurologicznych, takich jak choroba Alzheimera. Skuteczne leczenie może wpływać na redukcję tego ryzyka. </span>
                </span> */}


                <div className='consultationBody-content-img_text'>
                   
                    <div className='consultationBody-content-text'>
                        <div className='consultationBody-content-only_text-doubled-parent'>
                            <div className='consultationBody-content-only_text'>
                                <span className='bookmark-text'>Zbyt Krótki, Bądź Nieprawidłowy Sen Prowadzi Do:</span>
                                <span className='consultationBody-content-check-list-parent'>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> chorób somatycznych,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> zaburzeń psychicznych,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> wypadków komunikacyjnych,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> pogorszenia wyników w szkole/pracy,</span>
                                <span className='consultationBody-content-check-list-child'><BiCheckCircle /> istotnego obniżenia jakości życia.</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='consultationBody-content-img-container'>
                                    <Img_lazy_load
                                        isSrcSet={false}
                                        srcString={'/images/specjalnosci/spokojny-sen-i-jego-znaczenie.webp'}
                                        imgWidth={'931'}
                                        imgHeight={'310'}
                                        altString={'leczenie chrapania rzeszów'}
                                        classString={'consultationBody-content-img'} />
                    </div>
                </div>

                

                <hr className='separator-line-simple'></hr>

                <h2 className='h2-margin-top' >Przyczyny Chrapania i Bezdechu Sennego</h2>
                <span className='content-text'>Dolegliwość chrapania, jak i zespół ZOBS są związane z utrudnionym przepływem powietrza przez gardło. 
                            Najczęściej obserwuje się je u osób otyłych, po spożyciu większej porcji alkoholu albo kobiet po okresie menopauzy. 
                            Chrapanie nie zagraża zdrowiu jeżeli pojawia się sporadycznie, a powietrze jest w stanie swobodnie przepływać przez krtań.
                            Jeżeli jednak przerwy w oddychaniu pojawiają się ponad 10 razy na godzinę to znak, że masz do czynienia z bezdechem.
                </span>

                    <div className='consultationBody-content-img_text'>
                        <div className='consultationBody-content-text'>
                     
                        <div className='consultationBody-content-only_text'>
                                <span className='bookmark-text'>Najczęstszymi Przyczynami Chrapania, Jak i Bezdechu Sennego Są:</span>
                                <span className='consultationBody-content-check-list-parent content-text'>
                                <span className='consultationBody-content-check-list-child'>1) wydłużone, wiotkie podniebienie miękkie,</span>
                                <span className='consultationBody-content-check-list-child'>2) skrócony dystans pomiędzy brzegiem podniebienia a tylną ścianą gardła,</span>
                                <span className='consultationBody-content-check-list-child'>3) wydatne łuki podniebienno-gardłowe,</span>
                                <span className='consultationBody-content-check-list-child'>4) przerośnięta nasada języka,</span>
                                <span className='consultationBody-content-check-list-child'>5) powiększone migdałki,</span>
                                <span className='consultationBody-content-check-list-child'>6) przerośnięty języczek,</span>
                                <span className='consultationBody-content-check-list-child'>7) skrzywiona przegroda nosowa.</span>
                                </span>
                        </div>
                       

                        </div>
                   
                        <div className='consultationBody-content-img-container'>
                       
                                    <Img_lazy_load
                                        isSrcSet={false}
                                        srcString={'/images/specjalnosci/wizualizacja-problemu-chrapania.webp'}
                                        imgWidth={'980'}
                                        imgHeight={'613'}
                                        altString={'leczenie bezdechu sennego rzeszów'}
                                        classString={'consultationBody-content-img'} />
                        </div>
                    </div>

                    <h2>Potwierdzone Metody Leczenia Chrapania i Bezdechu Sennego</h2>
                    <span className='content-text'>Jedną z najczęstszych przyczyn chrapania jest <span className='bookmark-text'>nieodpowiedni styl życia</span>. Zanim skorzystasz z porady specjalisty, 
                        spróbuj zmienić pozycję snu, zrezygnuj z używek, zacznij zdrowo się odżywiać, zredukuj masę ciała (w przypadku nadwagi).
                        Bardzo możliwe, że to wystarczy, by pożegnać się z uciążliwym problemem. 
                        Jeżeli nie – udaj się na wizytę do laryngologa bądź pulmonologa. Specjaliści zlecą odpowiednie badania takie jak <span className='bookmark-text'>polisomnografię </span> 
                        i ustalą dalsze kroki leczenia. W przypadku, gdy powody chrapania to wady anatomiczne konieczne może być ich<span className='bookmark-text'> chirurgiczne usunięcie</span>. Możliwe jest także
                        <span className='bookmark-text'> leczenie aparatem CPAP</span>, polegające na zakładaniu specjalnej maski podłączonej do aparatury ułatwiającej oddychanie każdej nocy.
                    </span>

                    <h2 className='h2-margin-top'>Leczenie Chrapania i Bezdechu Sennego Aparatem CPAP</h2>
                    <span className='content-text'>
                     Metoda leczenia chrapania występującego przy obturacyjnym bezdechu sennym <span className='bookmark-text'>aparatem CPAP jest często uważana przez specjalistów za najlepszą i najbardziej skuteczną</span>.
                      Urządzenie generuje
                     strumień powietrza, który po przejściu przez specjalny filtr oczyszczający trafia do elastycznej rury. Rura prowadzi oczyszczone powietrze do szczelnie osadzonej maski na nosie lub ustach.
                     Korzystanie z aparatu CPAP podczas snu przyczynia się do eliminacji zatrzymań oddechu, co skutkuje redukcją chrapania oraz uniknięciem nieprzyjemnych nocnych pobudek.
                      Dzięki bezprzeszkodowemu przepływowi tlenu, aparat CPAP wspomaga utrzymanie swobodnego oddychania, co jest kluczowe dla zdrowego snu i ogólnego samopoczucia.
                    </span>

                    <h2 className='h2-margin-top'>Leczenie Chrapania i Bezdechów Sennych Operacyjnie</h2>
                    <span className='content-text'>Oferujemy Państwu możliwość przeprowadzenia małoinwazyjnych operacji w znieczuleniu miejscowym, wykonywanych
                        <span className='bookmark-text'> w Mini Clinic</span>, w gabinecie zabiegowym. Natomiast większe operacje, wymagające znieczulenia ogólnego, przeprowadzane są w warunkach sali operacyjnej w szpitalu.
                        W przypadkach, gdy inne metody leczenia nie przynoszą oczekiwanych rezultatów lub gdy powodem występowania bezdechów sennych i chrapania są wady anatomiczne, rozważane są operacyjne metody leczenia.
                         Operacje chirurgiczne związane z leczeniem chrapania i bezdechu sennego mogą obejmować:
                         <br /><br />
                        1)  <span className='bookmark-text'>Chirurgiczne usunięcie nadmiarowych tkanek w obrębie gardła</span>, takich jak <Link to='/zabiegi-w-znieczuleniu-miejscowym/termoablacja-podniebienie'><span className='internal-link'>podniebienie miękkie</span></Link>, <Link to='/zabiegi-w-znieczuleniu-miejscowym/uppp'><span className='internal-link'>części podniebienia miękkiego, języczka i modyfikacja położenia łuków podniebienno-językowych (przednich) i podniebienno-gardłowych (tylnych)</span></Link>, może pomóc w poprawie przepływu powietrza podczas snu i eliminacji chrapania.
                        <br /><br />
                        2) Jeśli problemy z przepływem powietrza przez nos przyczyniają się do chrapania i bezdechu sennego, operacje chirurgiczne mające na celu <Link to='/zabiegi-w-znieczuleniu-ogolnym/septoplastyka'><span className='internal-link'>korekcję przegrody nosa</span></Link>, <Link to='/zabiegi-w-znieczuleniu-ogolnym/usuniecie-polipow-nosa'><span className='internal-link'>redukcję polipów</span></Link>, <Link to='/zabiegi-w-znieczuleniu-ogolnym/operacja-zatok-przynosowych'><span className='internal-link'>otwarcie zatok</span></Link> oraz  <Link to='/zabiegi-w-znieczuleniu-miejscowym/termoablacja-nos'><span className='internal-link'>zmniejszenie masy małżowin nosowych dolnych</span></Link> mogą być skuteczną metodą leczenia.
                        <br /><br />
                        3) Inne procedury chirurgiczne. Istnieje wiele innych procedur chirurgicznych stosowanych w leczeniu chrapania i bezdechu sennego.
                    </span> <br /><br />

                    <h3 className='h2-margin-top center-text'><span className='bookmark-text '>Jeżeli Twoje chrapanie jest uciążliwe, odwiedź nas w Mini Clinic i raz na zawsze pożegnaj się z problemem. <br />
                     Zapraszamy do rejestracji (Tel. 790 759 759). Oferujemy kompleksowe konsultacje, diagnostykę i zabiegi operacyjne z dziedziny otolaryngologii.</span></h3>
            </div>
        </div>

            <div className='home-page-info-specjalizacja' >
             <Bottom_page_images />
            </div>
        
        </div>
</>
    );
}